<template>
  <div class="face-icon flex-column--align-center" :class="{ 'face-icon--valid': faceIcon.valid }">
    <div class="custom-icon cursor-default" :class="faceIcon.iconClass"></div>
    <div
      class="face-icon__correct custom-icon cursor-default"
      :class="{ 'icon-green-check-bold': faceIcon.valid, 'icon-red-cross-bold': !faceIcon.valid }"
    ></div>
    <span class="face-icon__label">{{ $t(faceIcon.label) }}</span>
  </div>
</template>

<script>
export default {
  name: 'FaceIconWithLabel',
  props: {
    faceIcon: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '~universkin-shared/src/assets/styles/scss/colors.scss';

.face-icon {
  width: 92px;

  &--valid {
    background-color: transparent;
  }

  &__correct {
    margin: 8px 0 7px 0;
  }

  &__label {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    font-family: var(--u-font-family-Helvetica);
    color: var(--u-color-grey-400);
  }
}

.icon-green-check-bold {
  margin-top: 20px;
}

@media (min-width: $desktop-start) {
  .face-icon {
    width: 89px;
    margin: 0 auto;
    padding-top: 10px;
    cursor: default;

    &--valid {
      background-color: $color-white;
      border-radius: 10px;
    }

    &__correct {
      margin: 15px 0 7px 0;
    }
  }
}
</style>
