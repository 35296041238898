<template>
  <take-a-photo-template
    :doctor-info="doctorInfo"
    :user-info="patientInfo"
    :language="locale"
    :language-options="languageOptions"
    :error="errorMessage"
    @change-language="onChangeLanguage"
    @take-photo="onTakePhoto"
    @back="onBack"
  />
</template>

<script>
import { mapActions } from 'vuex';

import TakeAPhotoTemplate from '@/modules/questionnaire/pages/take-photo/TakeAPhotoTemplate';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import { PHOTO_VALIDATION_ERROR_MODALS } from '@/modules/questionnaire/constants/photoAnalysis';

import { types } from '@/modules/questionnaire/store/types';

export default {
  name: 'PageTakeAPhoto',
  components: { TakeAPhotoTemplate },
  mixins: [changeLanguageMixin, doctorAndPatientInfoMixin],
  data() {
    return {
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions({
      savePatientPhoto: types.actions.SAVE_PATIENT_PHOTO
    }),
    async onTakePhoto(photo) {
      const { error } = await this.savePatientPhoto(photo);

      if (PHOTO_VALIDATION_ERROR_MODALS[error]) {
        await this.$asyncModal.show(PHOTO_VALIDATION_ERROR_MODALS[error]);

        return;
      }

      this.$router.replace({
        name: 'WeHaveGotYourPhoto'
      });
    },
    onBack() {
      this.$router.replace({ name: 'LetsMakeASelfie' });
    }
  }
};
</script>
