<template>
  <questionnaire-page-template
    :title="$t('makeSelfiePage.action.takeAPhoto')"
    :doctor-info="doctorInfo"
    :user-info="userInfo"
    :language-options="languageOptions"
    :language="language"
    :content-base-width="1400"
    no-footer
    no-divider
    basic-background
    @change-language="$emit('change-language', $event)"
  >
    <div class="take-photo-page-content">
      <div
        class="take-photo-page-content__text q-mt16 q-mb32 u-typography-helvetica u-text u-text--s"
      >
        {{ $t('takeAPhoto.text.putYourFace') }}
      </div>

      <div class="take-photo-page-content__video-wrapper video-wrapper">
        <div
          v-if="!isPhotoInstructionsShown"
          class="video-wrapper__info-icon icon-help-circle-white"
          @click="showPhotoInstructions"
        ></div>
        <video
          v-show="showWebCamera"
          ref="video"
          class="video-wrapper__web-camera"
          autoplay
        ></video>
        <canvas ref="canvas" width="720" height="400" class="video-wrapper__canvas-media"></canvas>

        <div v-if="isWebCameraShown && isPhotoInstructionsShown" class="photo-instructions-wrapper">
          <photo-instructions dark-background>
            <template #action-button>
              <u-button kind="secondary" size="small" dark @click.stop="hidePhotoInstructions">
                {{ $t('makeSelfiePage.action.gotIt') }}
              </u-button>
            </template>
          </photo-instructions>
        </div>
      </div>

      <div
        v-if="isWebCameraShown"
        class="take-photo-page-content__action-buttons action-buttons q-mt24"
      >
        <u-button class="action-buttons__button" size="small" kind="primary" @click="onTakePhoto"
          >{{ $t('makeSelfiePage.action.takeAPhoto') }}
        </u-button>
        <u-button
          class="action-buttons__button"
          size="small"
          kind="secondary"
          @click="$emit('back')"
          >{{ $t('action.back') }}
        </u-button>
      </div>
    </div>
  </questionnaire-page-template>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';
import PhotoInstructions from '@/modules/questionnaire/pages/lets-make-selfie/PhotoInstructions.vue';

import webCameraMixin from '@/modules/dashboard/components/mixins/webCameraMixin';

import { dataURItoBlob } from '@/api/helpers';

export default {
  name: 'TakeAPhotoTemplate',
  components: { QuestionnairePageTemplate, PhotoInstructions, UButton },
  mixins: [webCameraMixin],
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isWebCameraShown: false,
      hasConsent: false,
      isPhotoInstructionsShown: true
    };
  },
  async mounted() {
    await this.initializeWebCamera();
    this.isWebCameraShown = true;
  },
  beforeDestroy() {
    this.stopVideoStream();
  },
  methods: {
    hidePhotoInstructions() {
      this.hasConsent = true;
      this.isPhotoInstructionsShown = false;
    },
    showPhotoInstructions() {
      this.isPhotoInstructionsShown = true;
    },
    onTakePhoto() {
      this.capture();

      this.$emit('take-photo', dataURItoBlob(this.capturedImage));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';
@import '../../../../assets/css/common/icons.css';

.take-photo-page-content {
  &__text {
    text-align: center;
    color: var(--color-grey-600);
  }
}

.video-wrapper {
  position: relative;

  &__info-icon {
    position: absolute;
    top: 33px;
    right: 33px;
    cursor: pointer;
    z-index: 1;
  }

  &__web-camera {
    width: 100%;
    height: 100%;
    border-radius: 2%;
  }

  &__canvas-media {
    display: none;
  }
}

.photo-instructions-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.action-buttons {
  &__button {
    min-width: 215px;
    margin: 0 auto 8px;
  }
}

@media (min-width: $desktop-start) {
  .photo-instructions-wrapper {
    display: block;
  }
}
</style>
