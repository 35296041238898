import { isUndefined } from 'lodash';
import { mapActions } from 'vuex';

import rootTypes from '@/store/types';
import { WEB_CAMERA_DIMENSIONS } from '@/constants';

const webCameraMixin = {
  data() {
    return {
      video: {},
      canvas: {},
      capturedImage: null,
      videoStream: null,
      webCamMode: true
    };
  },
  computed: {
    showWebCamera() {
      return this.webCamMode;
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    async initializeWebCamera() {
      this.setLoading(true);
      this.video = this.$refs.video;

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: WEB_CAMERA_DIMENSIONS.WIDTH },
            height: { ideal: WEB_CAMERA_DIMENSIONS.HEIGHT }
          }
        });

        if (!isUndefined(this.video.srcObject)) {
          this.video.srcObject = this.videoStream;
        } else {
          this.video.src = window.URL.createObjectURL(this.videoStream);
        }

        await this.video.play();
        this.setLoading(false);
      }
    },
    capture() {
      this.canvas = this.$refs.canvas;
      this.canvas
        .getContext('2d')
        .drawImage(this.video, 0, 0, WEB_CAMERA_DIMENSIONS.WIDTH, WEB_CAMERA_DIMENSIONS.HEIGHT);
      this.capturedImage = this.canvas.toDataURL('image/jpeg', 0.8);
      this.webCamMode = false;
    },
    takeAnotherPicture() {
      this.webCamMode = true;
    },
    stopVideoStream() {
      if (this.videoStream.getTracks && this.videoStream.getTracks().length > 0) {
        this.videoStream.getTracks()[0].stop();
      }

      window.URL.revokeObjectURL(this.video.src);
    }
  }
};

export default webCameraMixin;
