<template>
  <div
    class="photo-instructions"
    :class="{ 'photo-instructions--dark-background': darkBackground }"
  >
    <div class="photo-instructions__description u-typography-helvetica u-text u-text--xs">
      {{ $t('makeSelfiePage.introText') }}
    </div>

    <div class="photo-instructions__face-icons face-icons flex">
      <div
        v-for="faceIcon in $options.faceIconsConfig"
        :key="faceIcon.label"
        class="face-icons__group flex"
      >
        <face-icon-with-label :face-icon="faceIcon" />
      </div>
    </div>
    <div class="photo-instructions__action-button flex--center--center">
      <slot name="action-button"></slot>
    </div>
  </div>
</template>

<script>
import FaceIconWithLabel from './FaceIconWithLabel.vue';

const FACE_ICONS_CONFIG = [
  { iconClass: 'icon-add-photo-first', label: 'makeSelfiePage.photoInstructions1', valid: true },
  {
    iconClass: 'icon-add-photo-second',
    label: 'makeSelfiePage.photoInstructions2',
    valid: false
  },
  {
    iconClass: 'icon-add-photo-third',
    label: 'makeSelfiePage.photoInstructions3',
    valid: false
  },
  {
    iconClass: 'icon-add-photo-fourth',
    label: 'makeSelfiePage.photoInstructions4',
    valid: false
  },
  {
    iconClass: 'icon-add-photo-fifth',
    label: 'makeSelfiePage.photoInstructions5',
    valid: false
  },
  {
    iconClass: 'icon-add-photo-sixth',
    label: 'makeSelfiePage.photoInstructions6',
    valid: false
  },
  {
    iconClass: 'icon-add-photo-seventh',
    label: 'makeSelfiePage.photoInstructions7',
    valid: false
  }
];

export default {
  name: 'PhotoInstructions',
  components: { FaceIconWithLabel },
  faceIconsConfig: FACE_ICONS_CONFIG,
  props: {
    darkBackground: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.photo-instructions {
  width: 312px;
  margin: 0 auto;
  padding: 16px;
  background-color: var(--u-color-grey-100);
  border-radius: 10px;

  &__description {
    display: none;
  }

  &__action-button {
    text-align: center;
  }

  &--dark-background {
    display: none;
  }
}

.face-icons {
  flex-wrap: wrap;

  &__group {
    justify-content: center;
    margin-bottom: 20px;

    &:first-child {
      width: 100%;
    }
  }
}

@media (min-width: $desktop-start) {
  .photo-instructions {
    display: block;
    width: 700px;
    padding: 30px 26px;

    &__description {
      display: block;
      width: 340px;
      margin: 0 auto 20px auto;
      text-align: center;
      color: var(--u-color-grey-900);
    }

    &--dark-background {
      background-color: rgba(0, 0, 0, 0.67);
      width: 100%;
      height: 99%;
      padding: 50px 26px;

      .photo-instructions__description {
        color: var(--u-color-grey-50);
      }
    }
  }

  .face-icons {
    flex-wrap: nowrap;
  }
}
</style>
